import "@popperjs/core";
import * as bootstrap from "bootstrap";

addEventListener("DOMContentLoaded", () => {
  window.initializeBootstrapTooltip = (elem) => {
    bootstrap.Tooltip.getOrCreateInstance(elem);
    elem.setAttribute("data-tooltip-initialized", true);
  };
  const tooltipTriggerList = document.querySelectorAll(
    '[data-bs-toggle="tooltip"]:not([data-tooltip-initialized=true])'
  );
  console.log({ tooltipTriggerList });
  Array.from(tooltipTriggerList).map((tooltipTriggerEl) =>
    window.initializeBootstrapTooltip(tooltipTriggerEl)
  );
  const event = new Event("BootstrapTooltipsReady");
  document.dispatchEvent(event);
});
